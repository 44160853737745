import { api_domain } from '../../config'
import axios from 'axios'

const url = `${api_domain}api/cc`
const cc ={
    get(keyword = null){
        return axios({
            url: url,
            params:{keyword : keyword, is_admin:true},
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-cc\n', e.message)
            return { status: e.response.status }           
        })
    },
    create(payload){
        return axios({
            url: url,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on create-cc\n', e.message)
            return { status: e.response.status }           
        })
    },
    edit(payload){
        return axios({
            url: url+'/update',
            data:payload,
            method:'PUT',
        }).catch((e)=>{
            console.log('Catch error on edit-cc\n', e.message)
            return { status: e.response.status }           
        })
    },
    display(payload){
        return axios({
            url: url+'-display',
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on display-cc\n', e.message)
            return { status: e.response.status }           
        })
    },
}
export default cc
