<template>
    <v-main style="background-color: #E5E5E5;">
        <v-layout style="min-height: 100vh">
            <v-flex shrink style="background-color: #FFF">
                <SideBar></SideBar>
            </v-flex>
            <v-flex>
                <router-view></router-view>
            </v-flex>
        </v-layout>
        <!-- <Footer type="website"></Footer> -->
    </v-main>
</template>

<script>
import SideBar from '../components/SideBar'
export default {
    name:'MainLayout',
    components:{
        SideBar
    }
}
</script>

<style>

</style>