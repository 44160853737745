import Service from '../../services'

const state ={
    groupPartList:[]
}

const getters={}
const actions={
    getgroupPartList({commit},keyword = null){
        return Service.groupPart.list(keyword).then((res)=>{
            if(res.status === 200){
                commit('setgroupPartList', res.data)
            }
        })
    }
}
const mutations={
    setgroupPartList(state,data){
        data.forEach(x => {
          x.image = x.image? JSON.parse(x.image):x.image;
          x.parts = x.parts? JSON.parse(x.parts):x.parts;
        });
        state.groupPartList = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}