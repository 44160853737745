<template>
  <div class="pa-2">
    <v-btn style="float: right" color="primary" @click="exportToPDF"
      >To PDF</v-btn
    >
    <div ref="orderlist">
      <v-container>
        <div class="text-left pa-1">
          <h3 class="text-decoration-underline">Order No : {{ order }}</h3>
          <span class="font-weight-bold">Name :</span> {{ address.fullName }}
          <span class="font-weight-bold">Tel :</span> {{ address.mobilePhone
          }}<br />
          <span class="font-weight-bold">Address :</span
          >{{ address.addressName }} <br />
          <span class="font-weight-bold">City :</span>{{ address.cityName }}
          <span class="font-weight-bold">Country :</span>
          {{ address.countryName }}
          <span class="font-weight-bold">Zip Code :</span>{{ address.zipCode }}
        </div>
        <div>
          <h3 class="mt-2 text-left text-decoration-underline">Order Detail</h3>
          <v-data-table
            :hide-default-footer="true"
            :items="orderList.cart.cart_item"
            style="border: solid 1px red"
          >
            <template v-slot:header="{}">
              <thead class="white--text" style="background-color: #555555">
                <tr>
                  <td class="text-center">SKU</td>
                  <td class="text-center">Name</td>
                  <td class="text-center">Quantity</td>
                  <td class="text-center">Unit Price</td>
                  <td class="text-center">Line Total</td>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">{{ item.sku }}</td>
                <td class="text-center">{{ item.part.name }}</td>
                <td class="text-center">{{ item.quantity }}</td>
                <td class="text-center">{{ item.price_per_piece }}</td>
                <td class="text-center">{{ item.total_price }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import { mapState } from "vuex";
export default {
  name: "OrderList",
  methods: {
    exportToPDF() {
      html2pdf(this.$refs.orderlist, {
        margin: 1,
        filename: `${this.order}.pdf`,
        image: { type: "jpeg" },
        jsPDF: {
          orientation: "p",
          unit: "mm",
          format: "a4",
        },
      });
    },
  },
  computed: {
    ...mapState({
      orderList: (state) => state.Order.order,
    }),
  },
  props: {
    itemList: {
      default: [],
    },
    order: {
      default: "",
    },
    address: {
      default: null,
    },
  },
};
</script>

<style>
</style>