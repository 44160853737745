<template>
  <div>
    <v-alert dark color="black">
      <h3 class="headline">Group Part</h3>
    </v-alert>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              solo
              flat
              :rules="rules"
              label="Name"
              v-model="groupPart.name"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="6">
            <v-autocomplete
              solo
              flat
              v-model="groupPart.motor_id"
              :rules="rules"
              outlined
              :items="motorList"
              item-text="value"
              item-value="id"
              label="Motor"
              required
            >
            </v-autocomplete>
          </v-col>
          <v-col class="pt-2 pb-0" cols="4">
            <v-autocomplete
              solo
              flat
              v-model="groupPart.type"
              :rules="rules"
              outlined
              :items="type"
              item-text="value"
              item-value="id"
              label="Type"
              required
            ></v-autocomplete>
          </v-col>
          <v-col class="pt-2 pb-0" cols="8">
            <v-text-field solo flat label="Description" outlined></v-text-field>
          </v-col>

          <v-col cols="12">
            <DragnDrop
              locationType="parts"
              @updateImgProduct="groupPart.image = $event"
              :imgList="groupPart.image ? groupPart.image : []"
            ></DragnDrop>
          </v-col>

          <v-flex align-end>
            <v-btn @click="dialog = true" class="mr-2 cust-btn-color" depressed>
              Create Part
            </v-btn>
            <v-btn @click="dialog_list = true" class="cust-btn-color" depressed>
              Browse Part
            </v-btn>
          </v-flex>

          <v-col cols="12">
            <v-data-table
              :items="groupPart.parts"
              style="border: solid 1px black"
            >
              <template v-slot:header="{}">
                <thead class="white--text" style="background-color: #555555">
                  <tr>
                    <td>Part Number</td>
                    <td></td>
                    <td>SKU</td>
                    <td>Name</td>
                    <td>Price (฿)</td>
                    <td>Amount</td>
                    <td>Description</td>
                    <td></td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item, index }">
                <tr>
                  <td align="center">
                    <v-btn outlined fab x-small
                      ><h3>{{ index + 1 }}</h3></v-btn
                    >
                    <!-- <v-text-field
                      readonly
                      :value="index + 1"
                      style="width: 60px"
                      v-model="item.number"
                      dense
                      class="mt-2 shrink"
                      solo
                      flat
                      type="number"
                      outlined
                    ></v-text-field> -->
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <v-btn
                        v-if="index !== 0"
                        @click="moveIndex(index, index - 1)"
                        class="mb-2"
                        x-small
                      >
                        <h2 class="green--text">
                          <i class="bi bi-caret-up"></i>
                        </h2>
                      </v-btn>
                      <v-btn
                        v-if="index + 1 !== groupPart.parts.length"
                        @click="moveIndex(index, index + 1)"
                        x-small
                      >
                        <h2 class="red--text">
                          <i class="bi bi-caret-down"></i>
                        </h2>
                      </v-btn>
                    </div>
                  </td>

                  <td>{{ item.sku }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.price }}</td>
                  <td>
                    <v-text-field
                      style="width: 60px"
                      v-model="item.default_amount"
                      dense
                      class="mt-2 shrink"
                      solo
                      flat
                      type="number"
                      outlined
                    ></v-text-field>
                  </td>
                  <td>{{ item.description }}</td>
                  <td><a @click="remove(index)">REMOVE</a></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col class="row" cols="12">
            <v-col cols="6">
              <v-btn block class="cust-border-btn-color" @click="cancel">
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :disabled="!valid"
                block
                class="cust-btn-color"
                @click="confirm"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-form>
      <v-dialog v-model="dialog" max-width="700" persistent>
        <div v-if="dialog" style="background-color: white">
          <PartForm
            @SucessClick="SucessPartClick"
            :model="{
              sku: null,
              name: null,
              price: null,
              description: null,
              product_stock: { stock: null },
            }"
          ></PartForm>
        </div>
      </v-dialog>
      <v-dialog v-model="dialog_list" max-width="700" persistent>
        <div v-if="dialog_list" style="background-color: white">
          <PartList
            @cancelClick="cancelClick"
            @SucessClick="SucessClick"
            :list="partList"
          ></PartList>
        </div>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DragnDrop from "../components/input/DragnDrop";
import PartForm from "../components/PartForm";
import PartList from "../components/PartList";
import Service from "../services";
export default {
  name: "GroupPartForm",
  props: {
    model: {
      type: Object,
    },
  },
  components: {
    DragnDrop,
    PartForm,
    PartList,
  },
  data() {
    return {
      dialog: false,
      dialog_list: false,
      rules: [(v) => !!v || "This Field is required"],
      type: [
        { id: 1, value: "Parts Manual" },
        { id: 2, value: "Parts Color" },
      ],
      valid: true,
      error: "",
      groupPart: {
        parts: [],
      },
    };
  },
  computed: {
    ...mapState({
      partList: (state) => state.Part.partList,
      brandList: (state) => state.Brand.brandList,
      motorList(state) {
        state.Motor.motorList.forEach((x) => {
          x.value = `${x.name} - ${x.year}`;
        });
        return state.Motor.motorList;
      },
    }),
  },
  async created() {
    if (this.model) {
      this.groupPart = this.model;
      this.groupPart.type = parseInt(this.groupPart.type);
      this.groupPart.image = JSON.parse(this.groupPart.image);
      let partList = [];
      this.groupPart.group_part_part.forEach((item) => {
        if (item.part) {
          item.part.default_amount = item.default_amount;
          partList.push(item.part);
        }
      });
      this.groupPart.parts = partList;
      console.log(this.groupPart);
    }
    await this.$store.dispatch("Part/getPartList");
  },
  methods: {
    moveIndex(old_index, new_index) {
      if (new_index >= this.groupPart.parts.length) {
        var k = new_index - this.groupPart.parts.length + 1;
        while (k--) {
          this.groupPart.parts.push(undefined);
        }
      }
      this.groupPart.parts.splice(
        new_index,
        0,
        this.groupPart.parts.splice(old_index, 1)[0]
      );
    },
    cancel() {
      this.$emit("SucessClick");
    },
    confirm() {
      if (this.$refs.form.validate()) {
        this.groupPart.parts.forEach((x, index) => {
          x.default_amount = x.default_amount
            ? x.default_amount
            : (x.default_amount = 1);
          x.number = index + 1;
        });
        this.$store.dispatch("Loading/onSetLoading", true);

        Service.groupPart.create(this.groupPart).then((res) => {
          if (res.status === 200) {
            this.$emit("SucessClick");
            this.$store.dispatch("Loading/onSetLoading", false);
          } else {
            this.$swal.fire({
              ...this.$swalError,
              text: "Add Group Part Error, please try again",
            });
            this.$emit("SucessClick");
            this.$store.dispatch("Loading/onSetLoading", false);
          }
        });
      }
    },
    SucessPartClick($event) {
      let part = $event;
      this.dialog = false;
      this.dialog_list = false;
      if (part) {
        part.number = null;
        part.default_amount = 1;
        this.groupPart.parts.push(part);
      }
    },
    cancelClick() {
      this.dialog = false;
      this.dialog_list = false;
    },
    SucessClick($event) {
      let part = $event;
      this.dialog = false;
      this.dialog_list = false;
      part.forEach((x) => {
        x.default_amount = 1;
      });
      this.groupPart.parts.push(...part);
    },
    remove(index) {
      this.groupPart.parts.splice(index, 1);
    },
  },
};
</script>

<style>
</style>