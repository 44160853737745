import Service from '../../services'

const state = {
    motorList: []
}

const getters = {}
const actions = {
    getMotorList({ commit }, keyword = null) {
        return Service.motor.get(keyword).then((res) => {
            if (res.status === 200) {
                commit('setMotorList', res.data)
            }
        })
    }
}
const mutations = {
    setMotorList(state,data){
        data.forEach(x => {
            x.image = x.image ? JSON.parse(x.image) : x.image;
        });
        state.motorList = data;

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}