import { api_domain } from '../../config'
import axios from 'axios'

const url = `${api_domain}api/motor-model`
const motor = {
    get(keyword = null){
        return axios({
            url: url,
            params: {keyword : keyword , is_admin:true},
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-motor\n', e.message)
            return { status: e.response.status }           
        })
    },
    create(payload){
        return axios({
            url: url,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on create-motor\n', e.message)
            return { status: e.response.status }           
        })
    },
    edit(payload){
        return axios({
            url: url+'/update',
            data:payload,
            method:'PUT',
        }).catch((e)=>{
            console.log('Catch error on edit-motor\n', e.message)
            return { status: e.response.status }           
        })
    },
    display(payload){
        return axios({
            url: url+'-display',
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on display-motor\n', e.message)
            return { status: e.response.status }           
        })
    }
}
export default motor