<template>
  <div>
    <v-alert dark color="black">
      <h3 class="headline">Model Management</h3>
    </v-alert>

    <div class="pa-4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              v-model="motor.name"
              outlined
              :rules="rules"
              label="Name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              dense
              v-model="motor.brand_id"
              outlined
              :items="brandList"
              :rules="rules"
              item-text="name"
              item-value="id"
              label="Brand"
              required
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              dense
              v-model="motor.cc_id"
              outlined
              :items="ccList"
              :rules="rules"
              item-text="name"
              item-value="id"
              label="CC"
              required
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              v-model="motor.year"
              outlined
              :rules="rules"
              label="Year"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="motor.description"
              dense
              outlined
              rows="3"
              label="Description"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <DragnDrop
              locationType="motor"
              :imgList="motor.image ? motor.image : []"
              @updateImgProduct="motor.image = $event"
            ></DragnDrop>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col cols="6">
            <v-btn block class="cust-border-btn-color" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :disabled="!valid"
              block
              class="cust-btn-color"
              @click="validate"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import Service from "../services/";
import DragnDrop from "../components/input/DragnDrop";
//import Service from "../services/";
export default {
  name: "MotorModelForm",
  props: {
    model: {
      type: Object,
    },
  },
  data: () => ({
    valid: true,
    motor: {},
    rules: [(v) => !!v || "This Field is required"],
  }),
  components: {
    DragnDrop,
  },
  computed: {
    ...mapState({
      brandList: (state) => state.Brand.brandList,
      ccList: (state) => state.CC.ccList,
    }),
  },
  created() {
    this.motor = this.model;
  },
  methods: {
    cancel() {
      this.$emit("SucessClick");
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.motor.id) {
          //edit
          this.$store.dispatch("Loading/onSetLoading", true);
          Service.motor.edit(this.motor).then((res) => {
            if (res.status === 201) {
              this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick");
            }
          });
        } else {
          //create
          this.$store.dispatch("Loading/onSetLoading", true);
          Service.motor.create(this.motor).then((res) => {
            if (res.status === 200) {
              this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick");
            }
          });
        }
      }
    },
  },
};
</script>

<style>
</style>