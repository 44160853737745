import Service from '../../services'

const state={
    partList:[],
    resultPart:[],
}
const getters={}
const actions={
    getPartList({commit}, keyword=null){
        return Service.part.get(keyword).then((res)=>{
            if(res.status === 200){
                commit('setPartList', res.data)
            }
        })
    },
    getResultPartList({commit},keyword){
        return Service.part.find(keyword).then((res)=>{
            if(res.status === 200){
                commit('setResultPart', res.data)
            }
        })
    }
}
const mutations={
    clearResultPart(state){
        state.resultPart = [];
    },
    setPartList(state,data){
        state.partList = data;
    },
    setResultPart(state,data){
        state.resultPart = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}