<template>
  <div class="py-5 px-5">
    <v-stepper non-linear>
      <v-stepper-header>
        <div v-for="item in shippingStep" :key="item.step">
          <v-stepper-step :complete="item.completed" :step="item.step">
            {{ item.description }}
          </v-stepper-step>
        </div>
      </v-stepper-header>
    </v-stepper>
    <div style="text-align: center; font-size: 2rem">
      Shipping Status :
      <div class="justify-center row">
        <v-select
          class="col-12 col-md-4"
          v-model="shippingStatus"
          :items="shippingStep"
          item-text="description"
          item-value="step"
          label="Status"
          @change="changeStepper"
        ></v-select>
      </div>
    </div>
    <div class="d-flex flex-row-reverse mt-5">
      <v-btn @click="$emit('onClose')">Close</v-btn>
      <v-btn @click="onSave" color="primary mr-3">Save</v-btn>
    </div>
  </div>
</template>

<script>
import Service from '../services'
export default {
  name: 'ShippingStatus',
  props: {
    status: {
      default: ''
    },
    id: {
      default: 0
    },
    checkout:{
      default: '',
      type:String
    },
    shipping:{
      default: '',
      type:String
    },
    row:{
      default: 5,
      type:Number
    }
  },
  data() {
    return {
      shippingStatus: '',
      shippingStep: [
        { step: 1, description: 'Checking', completed: false },
        { step: 2, description: 'Preparing', completed: false },
        { step: 3, description: 'Shipping', completed: false },
        { step: 4, description: 'Delivered', completed: false },
      ]
    }
  },
  computed: {
  },
  methods: {
    onSave() {
      let description = this.shippingStatus = this.shippingStep.find(
      (x) => x.step === this.shippingStatus
    ).description
    this.$store.dispatch("Loading/onSetLoading",true);
      Service.order
        .editShippingStatus(this.id, description)
        .then((res) => {
          if (res.status === 200) {
            this.$store.dispatch("Loading/onSetLoading",false);
            this.$emit('onClose')
            this.$store.dispatch('Order/onGetPaginateOrder', {
              page: 1,
              row: this.row,
              checkout: this.checkout,
              shipping: this.shipping,
              token:this.accessToken
            })
          } 
        })
    },
    changeStepper(step) {
      this.shippingStep.forEach((x) => {
        if (x.step <= step) x.completed = true
        else x.completed = false
      })
    }
  },
  created() {
    this.shippingStatus = this.shippingStep.find(
      (x) => x.description === this.status
    ).step
    this.changeStepper(this.shippingStatus)
  }
}
</script>

<style>
</style>