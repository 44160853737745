<template>
  <v-main style="height: 100%">
    <HeaderLabel label="Group Part"></HeaderLabel>
    <v-layout justify-center mt-5>
      <v-flex pa-4>
        <v-layout justify-space-between mb-7>
          <v-flex shrink>
            <v-layout>
              <v-flex>
                <v-text-field
                  class="mr-2"
                  v-model="keyword"
                  dense
                  outlined
                  label="Keyword"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-btn
                  @click="search"
                  depressed
                  dense
                  large
                  color="#c80404"
                  class="text-start white--text"
                >
                  Search
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink>
            <v-btn
              @click="clickRow(null)"
              depressed
              large
              color="#c80404"
              class="pr-7 text-start white--text"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add
            </v-btn>
          </v-flex>
        </v-layout>
        <v-row>
          <v-col>
            <v-data-table
              :items="groupPartList"
              style="border: solid 1px black"
            >
              <template v-slot:header="{}">
                <thead class="white--text" style="background-color: #555555">
                  <tr>
                    <td>Image</td>
                    <td>Name</td>
                    <td>Type</td>
                    <td>Motor Model</td>
                    <td>Display</td>
                    <td></td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr :class="{ itemHide: !item.is_active }">
                  <td align="center">
                    <v-img
                      width="150px"
                      :src="
                        item.image && item.image[0]
                          ? item.image[0]
                          : require('../assets/img/no-image.png')
                      "
                    ></v-img>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.type.value }}</td>
                  <td>{{ item.motor_model.name }}</td>
                  <td :class="[item.is_active ? 'green--text' : 'grey--text']">
                    {{ item.is_active ? "Show" : "Hide" }}
                  </td>

                  <td>
                    <a @click="clickRow(item)">Edit</a> |
                    <a
                      :class="[item.is_active ? 'grey--text' : 'green--text']"
                      @click="toggleDisplay(item)"
                    >
                      {{ item.is_active ? "Hide" : "Show" }}
                    </a>
                    | <a @click="removeItem(item)" class="red--text">Remove</a>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" max-width="900" persistent>
      <div v-if="dialog" style="background-color: white">
        <GroupPartForm @SucessClick="SucessClick" :model="row"></GroupPartForm>
      </div>
    </v-dialog>
  </v-main>
</template>

<script>
import HeaderLabel from "../components/HeadetLabel";
import GroupPartForm from "../components/GroupPartForm";
import { mapState } from "vuex";
import Service from "../services";
export default {
  name: "GroupPart",
  data() {
    return {
      row: {},
      keyword: null,
      dialog: false,
    };
  },
  components: {
    GroupPartForm,
    HeaderLabel,
  },
  methods: {
    async removeItem(item) {
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: `Are you sure to Remove this Group Part?`,
      });
      if (answer.isConfirmed) {
        Service.groupPart.delete(item.id).then((res) => {
          if (res.status === 200) {
            this.$store.dispatch("Loading/onSetLoading", false);
            this.$store.dispatch("GroupPart/getgroupPartList");
          }
        });
      }
    },
    search() {
      this.$store.dispatch("GroupPart/getgroupPartList", this.keyword);
    },
    clickRow(item) {
      if (item) {
        this.$store.dispatch("Loading/onSetLoading", true);
        Service.groupPart.get(item.id).then((res) => {
          if (res.status === 200) {
            this.$store.dispatch("Loading/onSetLoading", false);
            this.row = res.data;
            this.dialog = true;
          } else {
            this.$store.dispatch("Loading/onSetLoading", false);
          }
        });
      }else{
        this.row = item;
        this.dialog = true;
      }
    },
    SucessClick() {
      this.dialog = false;
      this.$store.dispatch("GroupPart/getgroupPartList");
    },
    async toggleDisplay(item) {
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: `Are you sure to ${
          item.is_active ? "hide" : "show"
        } this Group Part?`,
      });
      if (answer.isConfirmed) {
        this.$store.dispatch("Loading/onSetLoading", true);
        Service.groupPart.display(item).then((res) => {
          if (res.status === 200) {
            this.$store.dispatch("Loading/onSetLoading", false);
            this.$store.dispatch("GroupPart/getgroupPartList");
          }
        });
      }
    },
  },
  computed: {
    ...mapState({
      groupPartList: (state) => state.GroupPart.groupPartList,
    }),
  },
  async created() {
    await this.$store.dispatch("GroupPart/getgroupPartList");
  },
};
</script>

<style>
</style>