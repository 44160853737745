<template>
  <v-main style="height: 100%">
    <HeaderLabel label="Customer Order"></HeaderLabel>
    <v-skeleton-loader
      v-if="isLoading"
      class="mx-auto"
      max-width="auto"
      type="table"
    ></v-skeleton-loader>
    <div class="pa-4" v-if="!isLoading">
      <v-row justify="space-between" align="center">
        <v-col lg="8" cols="12" class="flex-center-col row">
          <v-col lg="4" cols="6">
            <v-select
              class="mr-2"
              :clearable="true"
              dense
              v-model="shipping_status"
              :items="statusList"
              label="STATUS"
              @change="search"
              outlined
            ></v-select>
          </v-col>
          <v-col lg="4" cols="6">
            <v-select
              dense
              :clearable="true"
              v-model="payment_status"
              :items="payby"
              label="PAY BY"
              @change="search"
              outlined
            ></v-select>
          </v-col>
          <v-col lg="4" cols="12">
            <v-text-field
              dense
              v-model="keyword"
              label="SEARCH"
              @change="search"
              outlined
            ></v-text-field>
          </v-col>
        </v-col>
        <v-col lg="2" cols="12">
          <v-select
            dense
            v-model="row"
            :items="row_selector"
            label="ROWS"
            @change="search"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <div class="text-right">
        <Excel
          :dataSource="dataSourceExcel"
          :fileName="fileName"
          @queryJSON="queryExcel"
        ></Excel>
      </div>
      <v-data-table
        class="mt-2"
        :hide-default-footer="true"
        :items="paginateOrders"
      >
        <template v-slot:header="{}">
          <thead class="white--text" style="background-color: #555555">
            <tr>
              <td class="text-center">Order no.</td>
              <td class="text-center">Name</td>
              <td class="text-center">Shipping Status</td>
              <td class="text-center">Pay</td>
              <td class="text-center">Total</td>
              <td class="text-center">Date</td>
              <td class="text-center">Tracking Number</td>
              <td class="text-center">Transport</td>
              <td class="text-center">Detail</td>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-center">{{ item.reference_number }}</td>
            <td class="text-center">{{ item.name }}</td>
            <td class="text-center">
              {{ item.shipping_status }}
              <v-icon
                v-if="item.shipping_status"
                medium
                class="mr-2"
                @click="editShipping(item)"
              >
                mdi-pencil
              </v-icon>
            </td>
            <td align="center">{{ item.pay_by }}</td>
            <td class="text-right">
              <v-chip color="green" text-color="white">
                {{ item.currency }}
                {{ item.transfer_amount ? item.transfer_amount : 0 }}
              </v-chip>
            </td>
            <td class="text-center">
              {{ item.created_at | moment("DD MMMM YYYY HH:mm:ss") }}
            </td>
            <td>{{item.tracking_number}}</td>
            <td>{{item.transport_company}}</td>
            <td class="d-flex justify-center pt-1">
              <v-btn
                @click="showTransfer(item)"
                v-if="item.pay_by == 'TRANSFER'"
                class="mr-1"
                color="success"
              >
                Slip
              </v-btn>
              <v-btn
                class="mr-1"
                color="primary"
                @click.stop="
                  editItem(
                    item.id,
                    item.cart.cart_item,
                    item.reference_number,
                    item.shipping_user_address
                  )
                "
              >
                Items
              </v-btn>
              <OrderExport 
                :addressBilling="JSON.parse(item.shipping_user_address).billing"
                :sell_rate="item.sell_rate"
                :vat="item.vat"
                :inCart="item.cart.cart_item"
                :deliveryCost="item.shipping_price"
                :cartDetail="item.cart"
                :currency="item.currency"
              ></OrderExport>
              <v-btn
                @click.stop="addTrackingNumber(item)"
                class="ml-1"
                color="primary"
                >Tracking Number</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-pagination
        v-model="pagination.page"
        :length="pagination.pageCount"
        circle
        @input="changePage"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialog_detail" width="600">
      <div style="background-color: white">
        <OrderList
          v-if="order"
          :order="refno"
          :itemList="order"
          :address="shipping"
        ></OrderList>
      </div>
    </v-dialog>
    <v-dialog v-model="dialog_shipping" width="1000">
      <div v-if="dialog_shipping" style="background-color: white">
        <ShippingStatus
          :checkout="payment_status"
          :shipping="shipping_status"
          :row="row"
          :id="order_id"
          :status="shipping"
          @onClose="dialog_shipping = false"
        ></ShippingStatus>
      </div>
    </v-dialog>
    <v-dialog v-model="dialog_transfer" max-width="600">
      <div v-if="dialog_transfer" style="background-color: white">
        <HeaderLabel label="Transfer Slip"></HeaderLabel>
        <v-container>
          <v-img
            class="mx-auto"
            width="50%"
            :src="
              transfer && transfer.slip_image
                ? transfer.slip_image
                : require('../assets/img/no-image.png')
            "
          ></v-img>

          <div class="d-flex justify-space-between align-center">
            <v-col class="pl-5">Transfer from Bank</v-col>
            <v-col class="text-right font-weight-bold"
              >{{ transfer.transfer_from_bank }}
            </v-col>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-space-between align-center">
            <v-col class="pl-5">Transfer to</v-col>
            <v-col class="text-right font-weight-bold"
              >{{ transfer.transfer_to_bank }}
            </v-col>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-space-between align-center">
            <v-col class="pl-5">Amount Transferred</v-col>
            <v-col class="text-right font-weight-bold"
              >{{ transfer.transfer_amount }}
            </v-col>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-space-between align-center">
            <v-col class="pl-5">
              Transfer from Account No. (last 4 digits)</v-col
            >
            <v-col class="text-right font-weight-bold"
              >{{ transfer.transfer_account_digit }}
            </v-col>
          </div>
        </v-container>
      </div>
    </v-dialog>
    <v-dialog v-model="dialog_transport" max-width="700">
      <div v-if="dialog_transport" style="background-color: white">
        <TrackingForm @SucessClick="sucessClick" :data="transport_data" :id="order_id"></TrackingForm>
      </div>
    </v-dialog>
  </v-main>
</template>

<script>
import OrderExport from "../components/input/OrderExport";
//import { formatNumber } from '@/plugins/global-funcs'
import { mapState } from "vuex";
import Service from "../services";
import Excel from "../components/input/Excel";
import ShippingStatus from "../components/ShippingStatus";
import TrackingForm from "../components/TrackingForm.vue";
// import AddressPanel from '../../components/AddressPanel'
import OrderList from "./OrderList";
import HeaderLabel from "../components/HeadetLabel";
import moment from "moment";
export default {
  name: "CustomerOrder",
  data() {
    return {
      //formatNumber: formatNumber,
      keyword: "",
      fileName: "",
      dataSourceExcel: [],
      shipping_status: "",
      payment_status: "",
      customer: {},
      order_id: 0,
      transfer: null,
      isLoading: false,
      row_selector: [5, 10, 15],
      row: 5,
      order: null,
      refno: null,
      shipping: null,
      currency: "SGD",
      dialog_detail: false,
      dialog_shipping: false,
      dialog_transport: false,
      dialog_transfer: false,
      transport_data:null,
      payby: ["PAYPAL", "CREDIT_CARD", "TRANSFER"],
      statusList: ["Checking", "Preparing", "Shipping", "Delivered", "Cancel"],
    };
  },
  methods: {
    getColor(status) {
      switch (status) {
        case "CONFIRM":
          return "green";
        case "WAITING":
          return "orange";
        case "TO RECEIVE":
          return "grey";
        case "TO BE SHIP":
          return "grey";
        case "COMPLETE":
          return "green";
      }
    },
    async queryExcel() {
      this.fileName = `customerorder${moment(new Date()).format(
        "-DD-MMM-YYYY"
      )}`;
      this.$store.dispatch("Loading/onSetLoading", true);
      await Service.order.getExcelOrder().then((res) => {
        if (res.status === 200) {
          this.$store.dispatch("Loading/onSetLoading", false);
          this.dataSourceExcel = res.data;
        }
      });
    },
    sucessClick() {
      this.dialog_transport = false;
      this.$store.dispatch("Order/onGetPaginateOrder", {
        page: this.pagination.page,
        row: this.row,
        checkout: this.payment_status,
        shipping: this.shipping_status,
        token: this.accessToken,
      });
    },
    addTrackingNumber(model) {
      this.order_id = model.id
      this.transport_data = {number:model.tracking_number ,transport:model.transport_company }
      this.dialog_transport = true;
    },
    editItem(id, item, refno, address) {
      this.$store.dispatch("Order/onGetOrderDetail", { order_id: id });
      this.order = item;
      this.refno = refno;
      this.shipping = JSON.parse(address).delivery;
      this.dialog_detail = true;
    },
    showTransfer(item) {
      this.transfer = item;
      //this.customer = item.shipping_user_address.delivery;
      this.dialog_transfer = true;
    },
    editShipping(item) {
      this.shipping = item.shipping_status;
      this.order_id = item.id;
      this.dialog_shipping = true;
    },
    async search() {
      this.isLoading = true;
      await this.$store.dispatch("Order/onGetPaginateOrder", {
        page: 1,
        row: this.row,
        checkout: this.shipping_status,
        pay_by: this.payment_status,
        token: this.accessToken,
        keyword: this.keyword,
      });
      this.isLoading = false;
    },
    async changePage(val) {
      this.isLoading = true;
      await this.$store.dispatch("Order/onGetPaginateOrder", {
        page: val,
        row: this.row,
        checkout: this.shipping_status,
        pay_by: this.payment_status,
        token: this.accessToken,
        keyword: this.keyword,
      });
      this.isLoading = false;
    },
  },
  components: {
    OrderList,
    HeaderLabel,
    TrackingForm,
    ShippingStatus,
    OrderExport,
    // AddressPanel,
    Excel,
  },
  computed: {
    ...mapState({
      //accessToken: (state) => state.Auth.userDetail.access_token,

      paginateOrders(state) {
        return state.Order.orders;
      },
      pagination(state) {
        return state.Order.pagination;
      },
    }),
  },
  created() {
    this.$store.dispatch("Order/onGetPaginateOrder", {
      page: 1,
      row: this.row,
      checkout: this.payment_status,
      shipping: this.shipping_status,
      token: this.accessToken,
    });
  },
};
</script>

<style>
</style>