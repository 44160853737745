import { api_domain } from '../../config'
import axios from 'axios'

const url = `${api_domain}api/group-part`
const groupPart = {
    get(id) {
        console.log(id);
        return axios({
            url: url,
            params: { id: id },
            method: 'GET',
        }).catch((e) => {
            console.log('Catch error on get-groupPart\n', e.message)
            return { status: e.response.status }
        })
    },
    list(keyword = null) {
        return axios({
            url: `${api_domain}api/list-groupPart`,
            params: { keyword: keyword, is_admin: true },
            method: 'GET',
        }).catch((e) => {
            console.log('Catch error on list-groupPart\n', e.message)
            return { status: e.response.status }
        })
    },
    create(payload) {
        return axios({
            url: url,
            data: payload,
            method: 'POST',
        }).catch((e) => {
            console.log('Catch error on create-groupPart\n', e.message)
            return { status: e.response.status }
        })
    },
    edit(payload) {
        return axios({
            url: url + '/update',
            data: payload,
            method: 'PUT',
        }).catch((e) => {
            console.log('Catch error on edit-groupPart\n', e.message)
            return { status: e.response.status }
        })
    },
    delete(id) {
        return axios({
            url: url + `/${id}`,
            method: 'DELETE',
        }).catch((e) => {
            console.log('Catch error on delete-part\n', e.message)
            return { status: e.response.status }
        })
    },
    display(payload) {
        return axios({
            url: url + '-display',
            data: payload,
            method: 'POST',
        }).catch((e) => {
            console.log('Catch error on display-groupPart\n', e.message)
            return { status: e.response.status }
        })
    },
}

export default groupPart
