<template>
  <div id="app">
    <v-app>
      <Loading :loading="isLoading"></Loading>
      <router-view/>
      <LoginDialog :value="!isLogin.access_token || loginDialog"></LoginDialog>
    </v-app>
  </div>
</template>
<script>
import LoginDialog from '@/components/LoginDialog'
import Loading from './components/input/Loading'
import { mapState } from 'vuex'
export default {
  name: 'App',

  components: {
      LoginDialog,
      Loading
  },
  created(){
    if(!this.isLogin.access_token){
      this.$router.push({ name: "home" });
    }
    this.$store.dispatch("Loading/onSetLoading",false);
  },
  computed:{
    ...mapState({
      isLoading: (state) => state.Loading.isLoading,
      isLogin: (state) => state.Auth.userDetail,
      loginDialog: (state) => state.Auth.loginDialog
    })
  },
};
</script>
<style scoped>
#app {
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
