<template>
  <div>
    <v-btn class="cust-btn-color" @click="onPrint"
      ><v-icon class="mr-2"> mdi-printer </v-icon>purchase order
    </v-btn>
  </div>
</template>

<script>
import "../../assets/font/Kanit-Regular-normal";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
export default {
  name: "OrderExport",
  props: {
    cartDetail: {
      default: null,
    },
    inCart: {
      default: null,
    },
    addressBilling: {
      default: null,
    },
    deliveryCost: {
      default: null,
    },
    total: {
      default: null,
    },
    currency: {
      default: null,
    },
    sell_rate: {
      default: null,
    },
    vat: {
      default: null,
    },
  },
  data() {
    return {
      cart: null,
    };
  },
  computed: {
    subTotal: function () {
      let sum = 0.0;
      for (const x of this.inCart) {
        let price = this.addVAT(this.specificExchangeRate(x.price_per_piece));
        sum += parseFloat(price * x.quantity, 2);
      }
      return parseFloat(sum) || parseFloat(sum) == 0 ? sum : "-";
    },
    totalIncludedTax: function () {
      let total =
        parseFloat(this.subTotal) +
        parseFloat(this.specificExchangeRate(this.deliveryCost));
      return parseFloat(total) || parseFloat(total) == 0 ? total : "-";
    },
  },
  created() {
    this.cart = this.inCart.map((x) => {
      return [
        x.sku,
        x.part ? x.part.name : '',
        {
          content: x.quantity.toString(),
          styles: { halign: "right" },
        },
        {
          content: this.formatNumber(
            this.specificExchangeRate(x.price_per_piece),
            2
          ),
          styles: { halign: "right" },
        },
        {
          content: this.formatNumber(
            this.addVAT(this.specificExchangeRate(x.total_price)),
            2
          ),
          styles: { halign: "right" },
        },
      ];
    });
  },
  methods: {
    formatNumber(x, toFixed = 0) {
      if (!x && x != 0) {
        return x;
      }
      if (toFixed === 0) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (toFixed === 2) {
        return x.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
    addVAT(price) {
      return price + price * this.vat;
    },
    specificExchangeRate(thb_price) {
      return this.sell_rate * thb_price;
    },
    onPrint() {
      let summary = [
        [
          null,
          null,
          null,
          {
            content: "VAT",
            styles: { halign: "right" },
          },
          {
            content: `${this.formatNumber(this.vat * 100, 2)} %`,
            styles: { halign: "right" },
          },
        ],
        [
          null,
          null,
          null,
          {
            content: "Shipping",
            styles: { halign: "right" },
          },
          {
            content: this.formatNumber(
              this.specificExchangeRate(this.deliveryCost),
              2
            ),
            styles: { halign: "right" },
          },
        ],
        [
          null,
          null,
          null,
          {
            content: "Total",
            styles: { halign: "right" },
          },
          {
            content: `${this.currency}  ${this.formatNumber(
              this.totalIncludedTax,
              2
            )}`,
            styles: { halign: "right" },
          },
        ],
      ];
      this.cart.push(...summary);
      const doc = new jsPDF();
      doc.setFont("Kanit-Regular", "normal"); // set font

      doc.addImage(
        require("../../assets/logo.jpg"),
        "JPEG",
        5,
        5,
        50,
        20
      );
      doc.setFontSize(25);
      doc.text("PURCHASE ORDER", 200, 15, {
        align: "right",
      });
      doc.setFontSize(15);
      doc.setTextColor("#C30505");
      doc.text("Hello", 10, 32);
      doc.setTextColor("#000000");
      doc.text("Custom", 24, 32);

      doc.setFontSize(10);
      doc.text("All about Accessories for Your Bike", 10, 38);

      doc.text(
        "Hello Custom\n" +
          "Bang Khlo, Bang Kho Laem, Bangkok 10120\n" +
          "+66 95 689 4280\n" +
          "hellocst209@gmail.com\n",
        10,
        50
      );

      doc.text("DATE", 170, 50, {
        align: "right",
      });
      doc.text(`${moment().format("MMMM Do YYYY")}`, 175, 50);

      doc.text("REFERENCE NUMBER", 170, 54, {
        align: "right",
      });
      doc.text(`${this.cartDetail.reference_number}`, 175, 54);

      doc.text("VENDOR", 10, 80);
      doc.text(
        "Hello Custom\n" +
          "258/9 param 3 No.12\n" +
          "Bang Khlo, Bang Kho Laem, Bangkok 10120\n" +
          "+66 95 689 4280\n",
        30,
        80
      );

      doc.text("SHIP TO", 120, 80);
      doc.text(
        `${this.addressBilling.fullName}\n` +
          `${this.addressBilling.addressName}\n` +
          `${this.addressBilling.cityName}  ${this.addressBilling.countryName}  ${this.addressBilling.zipCode}\n` +
          `${this.addressBilling.mobilePhone}\n`,
        140,
        80
      );
      var headers = [
        { content: "SKU", styles: { halign: "center" } },
        { content: "Name", styles: { halign: "center" } },
        { content: "Quantity", styles: { halign: "center" } },
        { content: "Unit Price", styles: { halign: "center" } },
        { content: "Line Total", styles: { halign: "center" } },
      ];
      doc.autoTable({
        headStyles: { fillColor: "#F4D1D1", textColor: "black" },
        styles: { font: "Kanit-Regular" },
        margin: { top: 105 },
        head: [headers],
        body: this.cart,
      });

      doc.setFont("Kanit-Regular", "normal");
      doc.text("Authorized Signature", 10, 272);
      doc.line(10, 275, 110, 275);
      doc.text("Date", 140, 272);
      doc.line(140, 275, 200, 275);

      doc.save(`${this.cartDetail.reference_number}.pdf`);
    },
  },
};
</script>

<style>
</style>